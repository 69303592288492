<template lang="pug">
    v-expansion-panels(
        accordion
        :multiple="isMultiple"
        :value="panel"
        v-bind="$attrs"
        :class="{'parent': !isChild}"
        @click.stop="handler"
    )
        v-expansion-panel(
            v-for="(item, i) in localQuestionSets"
            :key="`wrapper-${item.name}=${i}`"
        )
            v-expansion-panel-header(
                v-if="hasQuestions(item)"
                class="flex items-center"
                :key="`panel-header-${item.name}-${i}`"
                :class="{'text-black text-base font-display font-bold': !isChild, 'text-grey-1': isChild}"
                @click="goTo(i, item.questionSetId)"
            )
                component(
                    v-if="isChild"
                    :is="getSvgType(item)"
                    :key="`panel-svg-${item.name}-${i}`"
                    class="sub-icon mr-2 inline-block"
                )
                div(
                    :id="`accordionHeader-${i}-${item.questionSetId}`"
                    :ref="`accordionHeader-${i}-${item.questionSetId}`"
                    :class="{'sub-name': isChild}"
                    :key="`accordionHeaderText-${i}-${item.questionSetId}`"
                    v-html="item.name"
                )
                div(
                    v-if="!isChild && !fullSurvey && isDefaultTheme"
                    class="text-xs md:text-sm font-bold uppercase max-w-98 flex jsutify-between"
                    :key="`accordionHeaderColour-${i}-${item.questionSetId}`"
                    :class="getStatusClass(item)"
                )
                    | {{(checkComplete || !checkComplete) ? getStatus(item, i) : ''}}
                    fa-icon( v-if="isComplete(item)" :icon="getIconType(item)" class="ml-1")
            v-expansion-panel-content(
                v-if="hasQuestions(item)"
                class="questions-list"
            )
                div(
                    v-for="(question, idx) in item.combinedQuestionsAndSets"
                    :key="`question-set-${item.questionSetId}-${idx}`"
                )
                    // subaccordion
                    Question(
                        v-show="question.questionSetId && renderQuestion(question)"
                        :question-sets="[question]"
                        :is-child="true"
                        :first-child-open="isFirstChildOpen && i === 0"
                        :disabled="disabled"
                        :depends-on="question.dependsOnQuestionId"
                        :depends-on-value="getDependsOnValue(question)"
                        :question-set-idxs="{ localIdx: i, combinedIdx: idx }"
                        :question-set-id="item.questionSetId"
                        class="sub"
                        @save-form="submitForm"
                    )
                    QuestionOnly(
                        v-show="question.questionId && renderQuestion(question)"
                        ref="questionOnly"
                        :questions="[question]"
                        :disabled="disabled"
                        :question-set-id="item.questionSetId"
                        :question-set-idxs="{ localIdx: i, combinedIdx: idx }"
                        @save-form="submitForm"
                    )
</template>

<script>
import {FormBuilderMethods, FormConditionalChecks, FormRules} from '@/components/shared/mixins/formMixins'
import SubaccordionIncompleteSvg from '@/assets/icons/form/subaccordion_icon_incomplete.svg'
import SubaccordionCompleteSvg from '@/assets/icons/form/subaccordion_icon_complete.svg'
import EditableDatePicker from '@/components/basic/EditableDatePicker'
import RadioGroup from '@/components/basic/RadioGroup'
import FaceButtonGroup from '@/components/basic/FaceButtonGroup'
import SelectAndLabel from '@/components/basic/SelectAndLabel'
import {mapActions, mapGetters} from 'vuex'
import TextareaAndLabel from '@/components/basic/TextareaAndLabel'
import TextFieldAndLabel from '@/components/basic/TextFieldAndLabel'
import QuestionOnly from '@/components/forms/partials/QuestionOnly'
import {VALUE_MAPPING} from '@/modules/constants'
import Address from '@/components/basic/Address.vue'
import ContactDetails from '@/components/basic/ContactDetails.vue'
import {useTenantStylesStore} from '@/stores/useTenantStylesStore'
import {storeToRefs} from 'pinia'
import {usePrintFormStore} from '@/stores/usePrintFormStore'

export default {
    name: 'QuestionPanel',
    components: {
        SubaccordionIncompleteSvg, SubaccordionCompleteSvg,
        EditableDatePicker, RadioGroup, FaceButtonGroup,
        SelectAndLabel, TextareaAndLabel, TextFieldAndLabel,
        Address, QuestionOnly, ContactDetails,
        Question: () => import('@/components/forms/partials/QuestionTree') // because of the parent/child situation of Question, it has to be imported with a lambda
    },
    mixins: [ FormRules, FormBuilderMethods, FormConditionalChecks ],
    props: {
        questionSets: {
            type: Array,
            default: () => []
        },
        answerSets: {
            type: Array,
            default: () => []
        },
        isChild: {
            type: Boolean,
            default: false
        },
        firstChildOpen: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        openAllAccordions: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            rules: [this.required],
            isValid: true,
            isFirstChildOpen: false,
            panelModel: [],
            goToOptions: {
                duration: 500,
                ease: 'easeInOutQuad',
                offset: -500,
                container: '#app' // selector of the whole app since this is the thing that scrolls
            },
            localQuestionSets: [],
            localPureQuestionSets: [], // Just like localQuestionSets, but without combinedQuestionsAndSets. Used to return the value back.
            questionTypes: ['questionOnly'],
            checkComplete: false,
            indexForm: null,
        }
    },
    beforeMount() {
        this.localPureQuestionSets = [...(this.questionSets || [])]
        for (let questionSet of this.questionSets) {
            if (!questionSet.combinedQuestionsAndSets?.length) questionSet.combinedQuestionsAndSets = this.combineQuestionsAndQuestionSets(questionSet)
            this.setMapping(questionSet)
        }
    },
    mounted() {
        if (this.$device.mobile) {
            this.panelModel = this.questionSets.map((_, idx) => {
                return idx
            })
        } else {
            this.panelModel = []
        }
        // subscribe to all the questionSets conditional show/hide
        for (let questionSet of this.questionSets) {
            this.subscribeToDependencies(questionSet.combinedQuestionsAndSets)
        }
        // this.subscribeToDependencies(this.questionSets)
        this.localQuestionSets = [...(this.questionSets || [])]
    },
    methods: {
        ...mapActions(['updateQuestionMapping']),
        combineQuestionsAndQuestionSets(item) {
            const questionAndSets = [...(item?.mappedQuestionSets || []), ...(item?.questions || [])]

            // since order is an integer, we can return the left - right to get where the sort should put it
            return questionAndSets.sort((left, right) => left.order - right.order)
        },
        getSvgType(item) {
            const isCompleted = this.isComplete(item)
            return isCompleted ? 'SubaccordionCompleteSvg' : 'SubaccordionIncompleteSvg'
        },
        getIconType(item) {
            return this.isComplete(item) ? 'check' : ''
        },
        submitForm(questionSet = [], questionSetIdx = {}) {
            if (questionSet?.length && questionSetIdx?.localIdx != null) {
                this.localQuestionSets[questionSetIdx.localIdx].combinedQuestionsAndSets[questionSetIdx.combinedIdx] = questionSet[0]
                if (questionSet[0].value) {
                    // pureQuestionSets[questionSetIdx.localIdx].questions.map(quest => {
                    this.localPureQuestionSets[questionSetIdx.localIdx].questions.map(quest => {
                        if(quest.questionId === questionSet[0].questionId) {
                            quest.value = questionSet[0].value
                        }
                    })
                }
            }
            // console.log('submitting form with the questions here')
            this.indexForm = questionSetIdx.localIdx
            if (this.localPureQuestionSets[questionSetIdx.localIdx]) this.checkCompleteForm(this.localPureQuestionSets[questionSetIdx.localIdx])

            this.$emit('save-form', this.localPureQuestionSets, questionSetIdx.localIdx)
        },
        getStatus(item, i) {
            const isComplete = this.isComplete(item)
            if ((isComplete && i === this.indexForm) || isComplete) return 'Complete'
            return 'Incomplete'
        },
        checkCompleteForm(item) {
            this.checkComplete = !!this.isComplete(item)
        },
        getStatusClass(item) {
            const isComplete = this.isComplete(item)
            if (isComplete) return 'text-green'
            return 'text-red'
        },
        handler(event) {
            if (this.$device.mobile) event.preventDefault()
        },
        goTo(index, questionSetId) {
            const ref = document.getElementById(`accordionHeader-${index}-${questionSetId}`)
            setTimeout(() => {
                ref.scrollIntoView({behavior: 'smooth'})
            }, 800)
        },
        hasQuestions(item) {
            //  we have combined the questions and questionSets so we can order them, now we check if we have any
            return item.combinedQuestionsAndSets?.length
        },
        //TODO: need to figure out a more generic way to do this, because  code repeated in QuestionOnly
        // and this won't work at the moment because we aren't looking for the actual question
        // componentType if the `is` parameter
        /**
         * check the value of the depends on question so we can show/hide the current Question
         *
         * dependsOnComponentType is from the config?.is string of the dependsOn question, so we can check if we have
         * a select or a text-field so we can make sure we compare the value the correct way
         *
         * @param value
         * @param questions
         * @param key: the key to be able to get the correct id value from the Question or QuestionSet (this function accepts both)
         * @param dependsOnComponentType
         */
        checkForValue(value, questions = [], key = '', dependsOnComponentType = '', parentName) {
            if (!key || !questions?.length) return
            // if we have a questionSet we should only have one and we need to check all the questions underneath it first
            if (questions[0]?.typename === 'form_builder_hasura_question_set') {
                const question = questions.at(0)
                const subQuestions = question.combinedQuestionsAndSets?.length ?
                    question.combinedQuestionsAndSets.filter(c => c.questionId !== null) :
                    question.questions
                // for now we assume we only have questions
                this.checkForValue(value, subQuestions, 'questionId', dependsOnComponentType, question.name)
            }

            for(const question of questions) {
                let dependsOnValueIn = []
                if (question?.config?.dependsOnValueIn) dependsOnValueIn = question.config.dependsOnValueIn.split('|')

                if (dependsOnValueIn?.length) {
                    this.updateQuestionMapping({ questionId: question[key], value: dependsOnValueIn.indexOf(String(value)) > -1})
                } else if (value && (value instanceof Array || dependsOnComponentType?.toLowerCase().indexOf('select') > -1)) {
                    const mappedValue = value instanceof Array ?
                        value.map((v) => typeof v === 'string' ? v.toUpperCase().replace(/[\s,]/g, '_') : v) :
                        value.toUpperCase().replace(/[\s,]/g, '_')
                    const questionName = question.name || parentName
                    this.updateQuestionMapping({ questionId: question[key], value: mappedValue.indexOf(questionName.replace(/[\s,]/g, '_').toUpperCase()) > -1})
                    continue
                } else if (typeof value === 'string') {
                    const upperCaseValue = value.toUpperCase()

                    if (upperCaseValue in VALUE_MAPPING) {
                        this.updateQuestionMapping({ questionId: question[key], value: VALUE_MAPPING[upperCaseValue]})
                        continue
                    }
                }
                this.updateQuestionMapping({ questionId: question[key], value: !!value})
            }
        },
        setMapping(questionSet) {
            if (!questionSet.combinedQuestionsAndSets) return

            for (let question of questionSet.combinedQuestionsAndSets) {
                // show questionSets that don't have dependencies, hide ones that do on first load
                // this.updateQuestionMapping({ questionId: questionSet.questionSetId, value: !questionSet.dependsOnQuestionId})
                let key = 'questionSetId'
                if (!question[key]) key = 'questionId'
                this.updateQuestionMapping({ questionId: question[key], value: true})
                // now we check if we have to show the question
                if (question.dependsOnQuestionId) {
                    const formQuestion = questionSet.combinedQuestionsAndSets.find(q => q.questionId === question.dependsOnQuestionId)
                    if (formQuestion) this.checkForValue(formQuestion.value, [question], key, formQuestion?.config?.is)
                    else this.setMapping(question)
                }
            }
        },
        showQuestion(event) {
            const { questionId, value, componentType } = event
            if (!questionId) return
            let foundQuestionOrSets = []

            for (let i = 0; i < this.localQuestionSets.length; i++) {
                const questionSet = this.localQuestionSets[i]

                //It's because of this guy, we only find the first occurrence, need to find the specific one
                foundQuestionOrSets = questionSet.combinedQuestionsAndSets.filter(qs => qs.dependsOnQuestionId === questionId)

                // if we have found the question or questionSet we're looking for, we break out
                if (foundQuestionOrSets?.length) {
                    // qsIndex = i
                    break
                }
            }
            if (!foundQuestionOrSets?.length) return

            let key = 'questionId'
            // only need to check for questionId first because the question object has questionSetId on it so we can get it's parent
            if (!foundQuestionOrSets[0][key]) key = 'questionSetId'
            // changing the value into a boolean

            this.checkForValue(value, foundQuestionOrSets, key, componentType)
        },
        getDependsOnValue(question) {
            if (!question.dependsOnQuestionId) return

            return this.questionMapping[question.dependsOnQuestionId]
        },
        // TODO: consolidate the function in formMixins.js
        renderQuestion(question) {
            let key = 'questionSetId'
            if (!question[key]) key = 'questionId'
            if (!question[key]) return false
            if (!this.questionMapping) return false

            return this.questionMapping[question[key]]
        },
        validate() {
            const isValidList = []
            for (let qType of this.questionTypes) {
                //TODO: fix up the validate functionality so that each of the types has a validate function in it
                // have to check if qType is in this.$refs and if that ref is defined, for some reason, questionPanel is undefined
                if (qType in this.$refs && !!this.$refs[qType]) {
                    if (this.$refs[qType] instanceof Array) {
                        this.$refs[qType].forEach((comp) => {
                            isValidList.push(comp?.validate())
                        })
                    } else {
                         isValidList.push(this.$refs[qType]?.validate())
                    }
                }
            }
            //TODO: check to see if we need a form on this page, I suspect we don't
            // this.isValid = this.$refs.form.validate() && isValid
            this.isValid = isValidList.indexOf(false) === -1
            return this.isValid
        },
    },
    computed: {
        ...mapGetters(['isFullSurvey', 'questionMapping']),
        fullSurvey() {
            return this.isFullSurvey
        },
        panel() {
            if (!this.openAllAccordions && !this.isPrinting) return []
            return this.localQuestionSets?.map((_, idx) => idx)
        },
        isMultiple() {
            return !this.singleAccordionOpen || this.isPrinting
        }
    },
    watch: {
        localQuestionSets: {
            handler() {
                this.$emit('question-sets-updated', this.localQuestionSets)
            },
            deep: true
        }
    },
    destroyed() {
        for (let questionSet of this.localQuestionSets) {
            this.unsubscribe([questionSet])
        }
    },
    setup() {
        const tenantStylesStore = useTenantStylesStore()
        const { isDefaultTheme, singleAccordionOpen } = storeToRefs(tenantStylesStore)
        const printFormStore = usePrintFormStore()
        const { isPrinting } = storeToRefs(printFormStore)

        return { isDefaultTheme, singleAccordionOpen, isPrinting }
    }
}
</script>
