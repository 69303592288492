<template>
    <v-form
        v-model="isValid"
        ref="formInput"
        @submit.prevent
    >
        <div id="question-set-list" class="w-full">
<!--         class="grid grid-cols-2 gap-x-4 w-full">-->
            <div
                v-for="(item, index) of questionSets"
                :key="index"
                class="question-set"
            >
                <div
                    class="text-sm text-grey-info font-semibold mb-2"
                >{{ item.name }}</div>

                <div v-if="item.questions && item.questions.length" :class="{'pl-8 pt-1': indentQuestionGroupings}">
                    <component
                        v-for="(question, idx) in item.questions"
                        :key="`${item.questionSetId}-question-${idx}`"
                        :is="question.config.is"
                        :config="question.config"
                        :rules="getRulesFromConfig(question.config)"
                        :question-set-id="questionSetId"
                        :disabled="disabled"
                        v-bind="question.config"
                        v-model="question.value"
                        @blur="submitForm"
                        @value-changed="valueChanged"
                        @change="valueChanged"
                    ></component>
                </div>
            </div>
        </div>
    </v-form>
</template>

<script>
import {FormBuilderMethods, FormConditionalChecks, FormRules} from '@/components/shared/mixins/formMixins'
import RadioGroup from '@/components/basic/RadioGroup'
import FaceButtonGroup from '@/components/basic/FaceButtonGroup'
import EditableDatePicker from '@/components/basic/EditableDatePicker'
import PhoneNumber from '@/components/basic/PhoneNumber'
import SelectAndLabel from '@/components/basic/SelectAndLabel'
import TextareaAndLabel from '@/components/basic/TextareaAndLabel'
import TextFieldAndLabel from '@/components/basic/TextFieldAndLabel'
import {useTenantStylesStore} from '@/stores/useTenantStylesStore'
import {storeToRefs} from 'pinia'
import Address from '@/components/basic/Address.vue'
import ContactDetails from '@/components/basic/ContactDetails.vue'

export default {
    name: 'QuestionGroup',
    components: {
        RadioGroup,
        EditableDatePicker,
        PhoneNumber,
        FaceButtonGroup,
        SelectAndLabel,
        TextareaAndLabel,
        TextFieldAndLabel,
        Address,
        ContactDetails
    },
    mixins: [ FormRules, FormBuilderMethods, FormConditionalChecks ],
    props: {
        questionSets: {
            type: Array,
            default: () => []
        },
        answerSets: {
            type: Array,
            default: () => []
        },
        isChild: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        questionSetId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            isValid: true,
        }
    },
    methods: {
        submitForm() {
            this.$emit('save-form', this.questionSets)
        },
        valueChanged() {
            this.$emit('save-form', this.questionSets)
        },
        validate() {
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            return this.isValid
        },
    },
    destroyed() {
        this.unsubscribe(this.questionSets)
    },
    setup() {
        const tenantStylesStore = useTenantStylesStore()
        const { indentQuestionGroupings } = storeToRefs(tenantStylesStore)

        return { indentQuestionGroupings }
    }
}
</script>
