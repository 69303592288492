<template>
    <v-form
        :key="`form-${questionObjComputed.questionId}`"
        ref="formRef"
        :class="getFormClass(questionObjComputed)"
        v-model="isValid"
        lazy-validation
        @submit.prevent
    >
        <!--
            Note: we need all the events below as `event` is used for Ted and the click-triggered
                  and value-changed are used for all other form-builder apps
        -->
        <component
            v-if="renderQuestionVal && haveComponent"
            :key="`${uniqueId}-question-${questionObjComputed.questionId}`"
            ref="questionRef"
            :disabled="disabled"
            :is="questionObjComputed.config.is"
            :rules="getRulesFromConfig(questionObjComputed.config)"
            :config="questionObjComputed.config"
            :editing-form="editingForm"
            :class="{'disabled pointer-events-none': disabled}"
            v-bind="questionAttributes"
            v-model="questionObjComputed.value"
            @click-triggered="editElement(questionObjComputed)"
            @value-changed="valueChanged"
            @change="valueChanged"
            @blur="valueChanged"
        ></component>
    </v-form>
</template>

<script>
import RadioGroup from '@/components/basic/RadioGroup'
import FormTitle from '@/components/basic/FormTitle'
import FormSubtitle from '@/components/basic/FormSubtitle'
import SelectAndLabel from '@/components/basic/SelectAndLabel'
import CompleteFullSurvey from '@/components/shared/CompleteFullSurvey'
import FaceButtonGroup from '@/components/basic/FaceButtonGroup'
import TextareaAndLabel from '@/components/basic/TextareaAndLabel'
import TextFieldAndLabel from '@/components/basic/TextFieldAndLabel'
import PhoneNumber from '@/components/basic/PhoneNumber'
import NetPromoterScore from '@/components/forms/net-promoter-score/NetPromoterScore'
import NetPromoterButtonGroup from '@/components/basic/NetPromoterButtonGroup'
import CheckboxGroup from '@/components/basic/CheckboxGroup'
import {ref, computed, onMounted, watch} from '@vue/composition-api'
import {useFormFns} from '@/composables/form-fns'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import LogoTitle from '@/components/basic/LogoTitle'
import { uuid } from 'vue-uuid'
import CheckboxGroupWithComment from '@/components/basic/CheckboxGroupWithComment'
import LogoTitleUltimate from '@/components/basic/LogoTitleUltimate'
import Address from '@/components/basic/Address.vue'
import ContactDetails from '@/components/basic/ContactDetails.vue'
import {useFormAnswerStore} from '@/stores/useFormAnswerStore'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'QuestionDisplay',
    emits: ['value-changed', 'question-updated'],
    components: {
        RadioGroup,
        FormTitle,
        FormSubtitle,
        SelectAndLabel,
        CompleteFullSurvey,
        FaceButtonGroup,
        TextareaAndLabel,
        TextFieldAndLabel,
        PhoneNumber,
        NetPromoterScore,
        NetPromoterButtonGroup,
        CheckboxGroup,
        CheckboxGroupWithComment,
        LogoTitle,
        LogoTitleUltimate,
        Address,
        ContactDetails
    },
    // mixins: [FormRules, FormConditionalChecks],
    props: {
        question: {
            type: Object,
            required: true
        },
        editingForm: {
            type: Boolean,
            default: false
        },
        parentIdx: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        renderQuestion: {
            type: Boolean,
            default: false
        },
        questionSetId: {
            type: [Number, String],
            default: null
        },
    },
    setup(props, { emit }) {
        const uniqueId = ref(uuid.v4())
        const { getRulesFromConfig } = useFormFns()
        const formAnswerStore = useFormAnswerStore()
        const computedQuestionSetId = computed(() => props.questionSetId)
        const { updateAnswer } = formAnswerStore
        const formRef = ref({})
        const questionRef = ref({})
        const isValid = ref(true)
        const { questionMapping } = useGetters(['questionMapping'])
        const questionObjComputed = ref({})
        // const questionObjComputed = computed({
        //     get: () => {
        //         console.log('====== there should be a lot of these', props.question.questionSetId, props.question.questionId)
        //         return props.question
        //     },
        //     set: (questionObjVal) => {
        //         emit('question-updated', {question: questionObjVal, index: questionIndex.value})
        //         emit('value-changed', questionObjVal, questionIndex.value)
        //         updateAnswer(computedForm.value?.formId, computedForm.value?.version, questionObjComputed.value.questionSetId, questionObjComputed.value.questionId, questionObjComputed.value.questionCode)
        //     }
        // })

        //#region functions
        const valueChanged = async () => {
            // TODO: need to deprecate
            await emit('question-updated', {question: questionObjComputed.value, index: questionIndex.value})
            await emit('value-changed', questionObjComputed.value, questionIndex.value)
            updateAnswer(questionObjComputed.value?.formId, questionObjComputed.value?.formVersion, computedQuestionSetId.value, questionObjComputed.value.questionId, questionObjComputed.value.questionCode)
        }

        const editElement = (element) => {
            emit('edit-element', element, questionIndex.value)
        }

        const validate = () => {
            isValid.value = formRef.value.validate()
            if (questionRef.value && 'validate' in questionRef.value) isValid.value = questionRef.value?.validate()
            return isValid.value
        }

        const getFormClass = (questionObj) => {
            return questionObj?.config?.class || ''
        }
        //#endregion

        //#region computed properties
        const questionIndex = computed(() => props.parentIdx)
        const renderQuestionVal = computed(() => props.renderQuestion || questionMapping.value[questionObjComputed.value.questionId])
        const questionAttributes = computed(() => ({...questionObjComputed.value?.config, ...questionObjComputed.value?.config?.vuetifyAttrs}))
        const haveComponent = computed(() => {
            return questionObjComputed.value?.config?.is
        })
        // const renderQuestion = computed(() => questionMapping.value[questionObj.value.questionId])
        //#endregion

        onMounted(() => {
            questionObjComputed.value = { ...(props.question || {}) }
            // if (props.questionSetId) {
            //     console.log('======== calling update answer with: ', props.question)
            // }
        })

        watch(questionObjComputed, (newValue) => {
            emit('question-updated', {question: newValue, index: questionIndex.value})
            emit('value-changed', newValue, questionIndex.value)
            emit('input', newValue)
            if (newValue.questionId) updateAnswer(newValue.formId, newValue.formVersion, computedQuestionSetId.value, newValue.questionId, newValue.questionCode)
        }, { deep: true })

        return {
            formRef,
            questionObjComputed,
            questionRef,
            renderQuestionVal,
            isValid,
            questionAttributes,
            haveComponent,
            uniqueId,
            getRulesFromConfig,
            validate,
            valueChanged,
            editElement,
            getFormClass,
        }
    }
}
</script>
